import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {TableFooter} from "@mui/material";
import Api from "../Api";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {historyHeader} from "../translate";
import AmoCall from "../components/AmoCall";
import {userCrmRequest} from "../store/actions/users";

const {REACT_APP_SERVER} = process.env;

let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
let newArr = []

function Amo(props) {
    const [amo, setAmo] = useState([])
    const [single, setSingle] = useState(0)
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.users.crmData)
    useEffect(() => {
        (async () => {
            await dispatch(userCrmRequest())
        })()
    }, [])
    const [sipNum, setSip] = useState([]);


    const handleSipSelect = useCallback((sip, id) => {
        newArr.map(u => {
            if (u.userid === id) {
                u.sip = sip
            }
        })
        setAmo(prev =>
            prev.map(u => {
                if (u.id === id) {
                    return {...u, sip: sip};
                }
                return u;
            })
        );
    }, [])
    useEffect(() => {
        (async () => {
            const {data} = await Api.amoUsers();
            setAmo(data.data.users);
            newArr = data.data.users.map(u => ({userid: u.id, sip: u.sip || ''}))
        })()
    }, [])
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSip(data.inputNumber?.sip)
            } catch (e) {
            }
        })()
    }, []);

    const handleSave = useCallback(async (item) => {
        try {
            let f = newArr.find((s) => s.userid === item.id)
            if (item.sip) {
                if (item.crm_id) {
                    await Api.amoCrmEdit({...f, id: item.crm_id})
                    const {data} = await Api.amoUsers();
                    setAmo(data.data.users);
                    newArr = data.data.users.map(u => ({userid: u.id, sip: u.sip || ''}))
                    toast.success("Successfully edited")
                } else {
                    await Api.amoCrmCreate({...f, domain: userData?.amo?.domain})
                    const {data} = await Api.amoUsers();
                    setAmo(data.data.users);
                    newArr = data.data.users.map(u => ({userid: u.id, sip: u.sip || ''}))
                    toast.success("Successfully created")
                }
                setSingle(item.sip)

            } else if (item.crm_id) {
                await Api.amoCrmDelete(item.crm_id)
                const {data} = await Api.amoUsers();
                setAmo(data.data.users);
                newArr = data.data.users.map(u => ({userid: u.id, sip: u.sip || ''}))
                toast.success("Successfully deleted")

            }
        } catch (e) {

        }
    }, [newArr, userData])
    return (
        <Wrapper>
            <div className="tableParent" id="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <TableContainer
                    >
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="sip_header">
                                    <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                        Users of AMO - {userData?.amo?.domain}
                                    </TableCell>
                                </TableRow>
                                <TableRow className="sip_num">
                                    <TableCell
                                        align='center'
                                    />
                                    <TableCell align='center'>
                                        ID
                                    </TableCell>
                                    <TableCell align='center'>
                                        Full name
                                    </TableCell>
                                    <TableCell align='center'>
                                        Email
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        Type
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        Selected SIP
                                    </TableCell>

                                    <TableCell
                                        align='center'
                                    >
                                        Action
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody id="dataBody">
                                {amo?.length ? amo?.map((s, _) => (
                                    <TableRow key={_} hover style={{cursor: 'pointer'}} role="checkbox" tabIndex={-1}>
                                        <TableCell align="center" onClick={() => setSingle(s.sip)}>
                                            {s?.rights?.is_active ? <CheckCircleIcon style={{color: color}}/> : null}
                                        </TableCell>
                                        <TableCell onClick={() => setSingle(s.sip)} align="center">
                                            {s?.id}
                                        </TableCell>
                                        <TableCell onClick={() => setSingle(s.sip)} align="center" >
                                            {s.name}
                                        </TableCell>
                                        <TableCell onClick={() => setSingle(s.sip)} align="center" >
                                            {s.email}
                                        </TableCell>
                                        <TableCell align="center" onClick={() => setSingle(s.sip)}>
                                            {s?.rights?.is_admin ? 'Admin' : ''}
                                        </TableCell>
                                        <TableCell align="center" style={{width: 160}}>
                                            <Select
                                                styles={customStyles}
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                                onChange={(ev) => handleSipSelect(ev.value, s.id,)}
                                                menuPortalTarget={document.body}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                value={{label: s.sip, value: s.sip}}
                                                name="color"
                                                options={[{number: '', label: 'Select value'}, ...sipNum.map(k => (
                                                    {value: +k.number, label: `${k.number} ${k.context || ''}`}
                                                ))]}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <button className="addBtn" onClick={() => handleSave(s)}>
                                                Save
                                            </button>
                                        </TableCell>
                                    </TableRow>

                                )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                    <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                        no data

                                    </TableCell>


                                </TableRow>
                                }

                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                        <div style={{margin: 10}} className="crm_save_btn"/>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            {single?<AmoCall open={single} setOpen={setSingle}/>:null}
        </Wrapper>
    );
}

export default Amo;
