import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {input, menu, sip} from "../translate";
import {Helmet} from "react-helmet";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddSip from "../components/AddSip";
import EditSip from "../components/EditSip";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpShortWide, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import {sipDeleteRequest, sipLocalDelete, sipRequest} from "../store/actions/sip";
import Utils from "../Utils";
import qs from 'query-string'
import Cookies from "js-cookie";
import Account from "../helpers/Account";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditMoreSips from "../components/EditMoreSips";
import _ from 'lodash'
import TableLoader from "../components/TableLoader";
import Api from "../Api";
import SipIp from "../components/SipIP";
let titleArr = [
    'member',
    sip.phone[+localStorage.getItem('atsLang') || 1],
    sip.out[+localStorage.getItem('atsLang') || 1],
    sip.forward[+localStorage.getItem('atsLang') || 1],
    sip.note[+localStorage.getItem('atsLang') || 1],
    sip.outgoing[+localStorage.getItem('atsLang') || 1],
    sip.rec[+localStorage.getItem('atsLang') || 1],
    sip.crm[+localStorage.getItem('atsLang') || 1],
]
let descArr = []
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'
// let arr = []
function SipNumber() {
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(0)
    const [loading, setLoading] = useState(false)
    const [arr, setArr] = useState([])
    const [save, setSave] = useState(false)
    const [viewIp, setViewIp] = useState(false)
    const [code, setCode] = useState('')
    const [isSearch, setIsSearch] = useState(false)
    const [copied, setCopied] = useState(false)
    const sipData = useSelector(state => state.sip.sipData)
    const sipStatus = useSelector(state => state.sip.sipStatus)
    const [check, setCheck] = useState(false)
    const page = useSelector(state => state.sip.page)
    const total = useSelector(state => state.sip.total)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const profile = useSelector(state => state.users.ursData)

    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(sipRequest(Cookies.get("sip") || query.limit || 10, query.page || 1, query.search || '', query.sortBy || '', query.sortOrder || ''))
            setLoading(false)
        })()

    }, [location.search, sortOrder, sortBy])

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(async () =>{
            await dispatch(sipRequest(Cookies.get("sip") || query.limit || 10, query.page || 1, query.search || '', query.sortBy || '', query.sortOrder || ''))
        },10000)
        return () => clearInterval(intervalId);
    }, [])
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1 ? "Are you sure you want to delete?" :
                +localStorage.getItem('atsLang') === 2 ? "Вы уверены, что хотите судалить?" :
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(sipLocalDelete(id))
                await dispatch(sipDeleteRequest({sip: id, token: Account.getUrsRefreshToken()}))
            }

        } catch (e) {
            console.log(e)
        }
    }, [])

    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("sip",page)
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        query.page = 1

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])

    const handleSort = (column) => {
        query.sortBy = column
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            query.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setSortBy(column);
            setSortOrder('asc');
            query.sortOrder = 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }
    };
    useEffect(() => {
        if(!check){
            setArr([])
        }
    },[check])
    useEffect(() => {
        descArr = []
        if(!_.isEmpty(sipData)){
            sipData?.map(s => {
                descArr.push({member:s.member,number:s.buddy,notes:s.notes,out:s.sipdid,redirect:s.redirects?.channel,line:s.codelist,rec:s.rec,crm:s.crm?.length?'C':'',tel:s.telegram?.account_id?'T':''})
            })
        }else{
            descArr = []
        }
    },[sipData,descArr])

    const handleSelect = useCallback((ev,buddy) => {
        if(event.target.checked){
            setArr(prev => ([...prev,buddy]))
        }else{
            setArr(prev => prev.filter(b => b !== buddy))
        }

    }, [])
    const handleSelectAll = useCallback((ev) => {
        if(event.target.checked){
            setArr(descArr.map(r => r.number))
        }else{
            setArr([])
        }

    }, [descArr])

    const handleBigChange = useCallback(() => {
        setSave(true)
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    {menu.sip[+localStorage.getItem('atsLang') || 1]}
                </title>
            </Helmet>
            <Wrapper>
                {!_.isEmpty(profile) && profile?.admingroup?<div className="btn_block">
                    <button onClick={() => setViewIp(!viewIp)}>View IP</button>

                    {arr.length?<button onClick={() => Utils.sipExel(arr,sipData)}>Download</button>:null}
                    {arr.length?<button onClick={() => handleBigChange()}>Change</button>:null}
                    <button onClick={() => setCheck(!check)}>{check?'Uncheck SIPs':'Check SIPs'}</button>
                </div>:null}
                <div className="tableParent" id="tableParent">
                    {viewIp?<SipIp setViewIp={setViewIp}/>:
                        <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table className={sipStatus ==='request'?"myTableStyle":''} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={4}>
                                            {menu.sip[+localStorage.getItem('atsLang') || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15, padding: '0 35px', height: 50}} align="right"
                                                   colSpan={check?7:6}>
                                            <span className="d-flex justify-content-end align-items-center">
                                                <label className="search">
                                                    <input
                                                        value={query.search}
                                                        onChange={handleSearch}
                                                        placeholder={input.search[+localStorage.getItem('atsLang') || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">

                                        {check?<TableCell
                                            align='center'
                                        >
                                            <input type="checkbox" checked={arr.length === sipData?.length} onClick={handleSelectAll}/>
                                        </TableCell>:null}
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell align='center' onClick={() => handleSort('buddy')}>
                                            {sip.phone[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'buddy' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell align='center' onClick={() => handleSort('notes')}>
                                            {sip.note[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'notes' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>

                                        </TableCell>
                                        {window.innerWidth < 400 ? null : <TableCell
                                            onClick={() => handleSort('sipdid')}
                                            align='center'
                                        >
                                            {sip.out[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'sipdid' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>}
                                        {window.innerWidth < 400 ? null : <TableCell
                                            onClick={() => handleSort('redirects')}
                                            align='center'
                                        >
                                            {sip.forward[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'redirects' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>}
                                        {window.innerWidth < 400 ? null : <TableCell
                                            align='center'
                                            onClick={() => handleSort('codelist')}
                                        >
                                            {sip.outgoing[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'codelist' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>}
                                        <TableCell
                                            onClick={() => handleSort('rec')}
                                            align='center'
                                        >
                                            {sip.rec[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'rec' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('crm')}
                                            align='center'
                                        >
                                            {sip.crm[+localStorage.getItem('atsLang') || 1]}
                                            <span>{sortBy === 'crm' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('msg')}
                                            align='center'
                                        >
                                            MSG
                                            <span>{sortBy === 'msg' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            className="action"
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang') || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody id="dataBody">
                                    {loading?<TableLoader colSpan={10}/>:sipData.length  ? sipData.map((s, _) => (
                                        <TableRow key={_} hover role="checkbox" tabIndex={-1}>
                                            {check?<TableCell
                                                align='center'
                                            ><input type="checkbox" checked={arr.find(a => a === s.buddy)} onClick={(ev) => handleSelect(ev,s.buddy)}/></TableCell>:null}
                                            <TableCell align="center">
                                                {REACT_APP_SERVER === 'Beeline' ? (s?.sip_buddy?.regseconds > 0 && s?.sip_buddy?.port > 0 ?
                                                    <CheckCircleIcon style={{fontSize:18,color: color,marginLeft:10}}/> : null) : s?.ipaddr ?
                                                    <CheckCircleIcon style={{fontSize:18,color: color,marginLeft:10}}/> : null}
                                            </TableCell>
                                            <TableCell title={s?.sip_buddy?.ipaddr || s?.ipaddr ||  ''}
                                                       align="center" style={{cursor: 'pointer'}}
                                                       onClick={() => alert(`${s.buddy} ${s.secret}`)}>
                                                {s.buddy} {s.issip ? '(S)' : ''}
                                            </TableCell>
                                            <TableCell className="td_notes" align="center">
                                                {s.notes}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.sipdid}
                                            </TableCell>
                                            {window.innerWidth < 400 ? null : <TableCell align="center">
                                                {s?.rnums}
                                            </TableCell>}
                                            {window.innerWidth < 400 ? null : <TableCell align="center">
                                                {s?.codetypeResult?.map(m => <span key={m.id}> {m.later} </span>)}
                                            </TableCell>}
                                            {window.innerWidth < 400 ? null : <TableCell align="center">
                                                {+s.rec ? 'R' + s.rec : ''}
                                            </TableCell>}
                                            <TableCell align="center">
                                                {s?.crm?.length ? 'C' : ''}
                                            </TableCell>
                                            <TableCell align="center">
                                                {s?.telegram ? 'T' : ''}
                                            </TableCell>
                                            <TableCell className="action" align="center">
                                                <p style={{
                                                    fontSize: 13,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center'
                                                }}> <span onClick={() => {
                                                    setEdit(s.buddy)
                                                    setCode(s.secret)
                                                }} className="edit"><FontAwesomeIcon
                                                    icon={faPenToSquare}/></span>
                                                    {+profile?.admingroup === 1 ?
                                                        <span onClick={() => handleDelete(s.buddy)}
                                                              className="delete"><FontAwesomeIcon
                                                            icon={faTrashCan}/></span> : null}

                                                </p>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                        <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                            no data

                                        </TableCell>


                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, descArr)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, descArr)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={total || 1}
                                rowsPerPage={Cookies.get("sip") || query.limit || 10}
                                page={query.page - 1 || page - 1 || 0}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev, newPage) => handleLimit(newPage.props.value)}
                            />
                        </div>

                    </Paper>}
                    {copied ? <div className="copied">Copied</div> : null}
                </div>
                {create ? <AddSip open={create} setOpen={setCreate}/> : null}
                {edit ? <EditSip code={code} open={edit} setOpen={setEdit}/> : null}
                {arr ? <EditMoreSips arr={arr} open={save} setOpen={setSave}/> : null}
            </Wrapper>
        </>
    );
}

export default SipNumber;
