import {call, put, takeLatest} from 'redux-saga/effects';
import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS, URS_FAIL, URS_REQUEST, URS_SUCCESS, USER_CRM_REQUEST,USER_CRM_SUCCESS,USER_CRM_FAIL, USERS_FAIL, USERS_REQUEST, USERS_SUCCESS,
} from '../actions/users';
import Api from '../../Api';
import {toast} from "react-toastify";
import { navigateTo } from '../actions/navigateTo';
function* handleLoginRequest(action) {
    try {
        const {phone, password} = action.payload;
        const {data} = yield call(Api.login, {phone, password});
        yield put({
            type: LOGIN_SUCCESS,
            payload: {data},
        });
        yield put(navigateTo('/'));
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: LOGIN_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleUserProfileRequest() {
    try {
        const {data} = yield call(Api.profile);
        yield put({
            type: USERS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: USERS_FAIL,
            payload: {error: e.response},
        });
    }
}
function* handleUrsProfileRequest() {
    try {
        const {data} = yield call(Api.profileUrs);
        yield put({
            type: URS_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: URS_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleUserCrmRequest() {
    try {
        const {data} = yield call(Api.userCrm);
        yield put({
            type: USER_CRM_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: USER_CRM_FAIL,
            payload: {error: e.response},
        });
    }
}


export default function* watcher() {
    yield takeLatest(LOGIN_REQUEST, handleLoginRequest);
    yield takeLatest(USERS_REQUEST, handleUserProfileRequest);
    yield takeLatest(URS_REQUEST, handleUrsProfileRequest);
    yield takeLatest(USER_CRM_REQUEST, handleUserCrmRequest);

}
