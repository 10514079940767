export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAIL = 'USERS_FAIL';

export function profileRequest() {
    return {
        type: USERS_REQUEST,
        payload: {},
    };
}
export const URS_REQUEST = 'URS_REQUEST';
export const URS_SUCCESS = 'URS_SUCCESS';
export const URS_FAIL = 'URS_FAIL';

export function profileUrsRequest() {
    return {
        type: URS_REQUEST,
        payload: {},
    };
}
export const USER_CRM_REQUEST = 'USER_CRM_REQUEST';
export const USER_CRM_SUCCESS = 'USER_CRM_SUCCESS';
export const USER_CRM_FAIL = 'USER_CRM_FAIL';

export function userCrmRequest() {
    return {
        type: USER_CRM_REQUEST,
        payload: {},
    };
}

export function profileRequestLocal(data) {
    return {
        type: USERS_SUCCESS,
        payload: {data},
    };
}
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export function loginRequest({phone,password}) {
    return {
        type: LOGIN_REQUEST,
        payload: {phone,password},
    };
}

export const USERS_ADD_LOCAL = 'USERS_ADD_LOCAL';

export function callForwardLocal(data) {
    return {
        type: USERS_ADD_LOCAL,
        payload: { data },
    };
}
export const USERS_DELETE_LOCAL = 'USERS_DELETE_LOCAL';

export function callForwardDeleteLocal(id) {
    return {
        type: USERS_DELETE_LOCAL,
        payload: { id },
    };
}



