import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {logs, menu} from "../translate";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableLoader from "../components/TableLoader";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LogsFilter from "../components/LogsFilter";
import {logRequest} from "../store/actions/logs";
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {historyRequest} from "../store/actions/history";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import LogDetail from "../components/LogDetail";

function Logs(props) {
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState(0)
    const log = useSelector(state => state.logs.logsData)
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const count = useSelector(state => state.logs.count)
    const page = useSelector(state => state.logs.page)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    useEffect(() => {
        (async () => {
            let yourDate = new Date()
            yourDate.setDate(yourDate.getDate() + 1)
            const fiveDaysAgo = new Date();
            fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
            setLoading(true)
            await dispatch(logRequest(query.limit || 10, query.page || 1, {
                endTime:  query.endTime || yourDate.toISOString().split('T')[0],
                startTime:  query.startTime || fiveDaysAgo.toISOString().split('T')[0] ,
                type:query.type || '',
                isAdmin:query.isAdmin || '',
                method:query.method || '',

            }));
            setLoading(false)
        })()
    }, [])

    const [expanded, setExpanded] = useState(true)
    const handleLimit = useCallback(async (page) => {
        query.limit = page
        Cookies.set("history",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(logRequest(page || 10, query.page || 1, {
            endTime:  query.endTime ,
            startTime:  query.startTime ,
            type:query.type || '',
            isAdmin:query.isAdmin || '',
            method:query.method || '',

        }));
    }, [location.search])
    const handleChangePage = useCallback(async (page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(logRequest(query.limit || 10, query.page || 1, {
            endTime:  query.endTime ,
            startTime:  query.startTime  ,
            type:query.type || '',
            isAdmin:query.isAdmin || '',
            method:query.method || '',
        }));
    }, [location.search])
    return (
        <Wrapper>
            <div className="tableParent" id="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <Accordion expanded={expanded}>
                        <AccordionSummary
                            style={{
                                padding: '0 30px',
                                height: 50,
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                            aria-expanded="true"
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="w-100"
                                 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <h2 style={{fontSize: 20, margin: 0}}>
                                        {menu.logs[+localStorage.getItem('atsLang') || 1]}
                                    </h2>
                                    <div className="header_border" style={{height: 40, marginBottom: 15}}/>

                                    <h2 style={{fontSize: 20, margin: 0}}>
                                        <Link style={{fontSize: 20, margin: 0}} to="/statistic">Activities</Link>
                                    </h2>
                                </div>

                                <span style={{display: 'flex', alignItems: 'center'}}>
                                    <CalendarMonthIcon style={{transition: 'transform 0s'}}
                                                       onClick={() => setExpanded(!expanded)}/>
                                </span>
                            </div>
                        </AccordionSummary>
                        <hr className="m-0"/>

                        <AccordionDetails>
                            <LogsFilter />
                        </AccordionDetails>
                    </Accordion>
                    <hr style={{borderColor: '#d4d4d4', margin: 0}}/>
                    <TableContainer
                    >
                        <Table className={!loading ? "myTableStyle" : ''} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'>
                                        {logs.timestamp[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>
                                    <TableCell align='center'>
                                        {logs.method[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        {logs.admin[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        {logs.source[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        IP
                                    </TableCell>
                                    <TableCell
                                        align='center'
                                    >
                                        {logs.detail[+localStorage.getItem('atsLang') || 1]}

                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody id="dataBody">
                                {loading ? <TableLoader colSpan={10}/> : log?.length ? log?.map((s, _) => (
                                    <TableRow key={_} hover role="checkbox" tabIndex={-1}>
                                        <TableCell
                                            align="center" style={{cursor: 'pointer'}}>
                                            {new Date(s.date).toLocaleString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}
                                        </TableCell>
                                        <TableCell className="td_notes" align="center">
                                            {s.method}
                                        </TableCell>
                                        <TableCell align="center">
                                            {s.admin ? s.admin : "N"}
                                        </TableCell>
                                        <TableCell align="center">
                                            {s.link}
                                        </TableCell>
                                        <TableCell align="center">
                                            {s.ip}
                                        </TableCell>
                                        <TableCell align="center" onClick={() => setSingle(s.id)}>
                                            {!s.link.includes('login') && !s.link.includes('confirm')?<span
                                                   className="edit"><FontAwesomeIcon
                                                 icon={faEye}/></span>:null}
                                        </TableCell>
                                    </TableRow>

                                )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                    <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                        no data

                                    </TableCell>


                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="foot_footer d-flex align-items-center justify-content-end">
                        <TablePagination
                            className="hello"
                            rowsPerPageOptions={[10, 25, 100, {label: 'All', value: -1}]}
                            component="div"
                            count={count || 1}
                            rowsPerPage={query.limit || 10}
                            page={query.page - 1 || page - 1 || 0}
                            onPageChange={(event, newPage) => handleChangePage(newPage)}
                            onRowsPerPageChange={(ev, newPage) => handleLimit(newPage.props.value)}
                        />
                    </div>

                </Paper>
            </div>

            {single?<LogDetail open={single} setOpen={setSingle}/>:null}

        </Wrapper>
    );
}

export default Logs;
