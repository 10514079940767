import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Api from "../Api";
import img from "../assets/img/retail.png"
import {Box, Fade, Modal} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import {retail, global, input} from "../translate";
import {toast} from "react-toastify";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};
function RetailEnter({open,setOpen}) {
    const [domain, setDomain] = useState('')
    const [code, setCode] = useState('')
    const navigate = useNavigate()
    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        try {
            await Api.retail_domain({code, domain:domain+'.retailcrm.ru'})
            navigate('/crm_integration/retail')
        } catch (e) {
            toast.error('Error happened')
        }
    }, [domain,code])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>

                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between">
                            <h3>{retail.header[+localStorage.getItem('atsLang')  || 1]}</h3>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen('')
                                navigate('/crm_integration')
                            }}>
                            x
                            </span>
                        </div>
                        <p className="crm_title"><span
                            className="number">1. {retail.title1[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph">
                            <p>{retail.paragraph2[+localStorage.getItem('atsLang')  || 1]}</p>
                            <label className="count d-flex flex-row"><input value={domain} onChange={(ev) => setDomain(ev.target.value)} style={{width: '40%',}} /><p>.retailcrm.ru </p></label>
                        </p>
                        <p className="crm_title"><span
                            className="number">2. </span><span>{retail.title2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{retail.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}</span>
                            <img style={{width: 325}} src={img} alt=""/>
                        </p>
                    </form>
                    <div id="transition-modal-description sip_content" className="test_mode">
                        <label className="count" style={{width: '93%',marginBottom:30}}>
                            <p style={{marginBottom:30}}>{retail.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}</p>
                            <input placeholder="jZJFCfM084pO6*******************" value={code} onChange={(ev) => setCode(ev.target.value)}/>
                        </label>
                        <div className="d-flex justify-content-center">
                            <button style={{padding: '6px 17px'}} className="addBtn" onClick={handleSubmit}>
                                {global.edit[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default RetailEnter;
