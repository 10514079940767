import React, {useCallback, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import img from "../assets/img/logo22.png";
import uc from '../assets/img/image_ucom.png'
import Account from "../helpers/Account";
import LockIcon from "@mui/icons-material/Lock";
import {menu} from "../translate";
import Api from "../Api";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TestMode from "../components/TestMode";
import ForumIcon from '@mui/icons-material/Forum';
import Comment from "../components/Comment";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {profileRequest, profileUrsRequest} from "../store/actions/users";
import Cookies from "js-cookie";
import qs from "query-string";
import Billing from "../components/Billing";
import UrsAdd from "../components/UrsAdd";
import UrsReport from "../components/UrsReport";
import UrsVoip from "../components/UrsVoip";
import {Helmet} from "react-helmet";
import {REACT_APP_API_URL} from '../config';
import ats from '../assets/img/logo_light.png'
import BillingPop from "../components/BillingPop";
import TestPop from "../components/TestPop";
import DisabledPop from "../components/DisabledPop";
import MatrixPop from "../components/MatrixPop";
import axios from "axios";
import UrsHeader from "../components/UrsHeader";
import {didDelete, didDeleteLocal} from "../store/actions/did";

const {REACT_APP_SERVER} = process.env;

function Urs(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const profile = useSelector(state => state.users.ursData)
    const [data, setData] = useState({})
    const [test, setTest] = useState({id: 0})
    const [comment, setComment] = useState(false)
    const [billing, setBilling] = useState({member: 0, name: ''})
    const [add, setAdd] = useState(false)
    const [search, setSearch] = useState('')
    const [gumar, setGumar] = useState('')
    const [report, setReport] = useState('')
    const [voip, setVoip] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (!Account.getUrsToken()) {
            navigate('/admin_login')
        }
    }, [])
    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setData([])
                const {data} = await Api.members(query.page || 1, query.search || '', query.billing || '', query.testmode || '', query.isblocked || '', query.matrix || '', query.gumar || '')
                setData(data)
                setSearch(query.search || '')
                setLoading(false)

            } catch (e) {
            }
        })()
    }, [location.search])

    useEffect(() => {
        (async () => {
            await dispatch(profileUrsRequest())
        })()
    }, [])

    const handlePagination = useCallback(async (num) => {
        query.page = num;
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    const handleTest = useCallback(async (id) => {
        try {
            if (data.members.find(m => m.id === id).testmode) {
                await Api.test_mode(id, 'off', location.pathname)
            }
            setData((prevData) => {
                const updatedMembers = prevData.members.map((member) => {
                    if (+member.id === +id) {
                        if (!member.testmode) {
                            setTest(member)
                        } else {
                            return {...member, testmode: !member.testmode, test_period: null};
                        }
                    }
                    return member;
                });
                return {...prevData, members: updatedMembers};
            });

        } catch (e) {
            console.log(e)
            toast.error(e.response.data.message)
        }
    }, [data]);

    const handleId = useCallback(async (id) => {
        try {
            Cookies.set('viewer', id)
            Cookies.set('original', profile.id)
            Cookies.set('key', profile.uid)
            const {data} = await Api.loginAsUser(id, profile.id, profile.uid)
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            await dispatch(profileRequest())
            window.open(REACT_APP_API_URL, '_blank');
            // navigate('/')
        } catch (e) {

        }
    }, [profile, REACT_APP_API_URL])
    const handleSearch = useCallback((ev) => {
        ev.preventDefault()
        if(search){
            query.search = search;
        }
        if(gumar){
            query.gumar = gumar;
        }
        query.page = '';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [search, location.search, gumar])
    const handleBlock = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to change status?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите изменить?":
                    "Վստա՞հ եք, որ ցանկանում եք փոփոխել տողը";

            if (confirm(text) === true) {
                try {
                    setLoading(true)
                    await Api.blocked(id, +!data.members.find(m => m.id === id).isblocked)
                    setData((prevData) => {
                        const updatedMembers = prevData.members.map((member) => {
                            if (member.id === id) {
                                if(member.isblocked){
                                    return {...member,testmode:0, isblocked: !member.isblocked};
                                }else{
                                    return {...member, isblocked: !member.isblocked};

                                }
                            }
                            return member;
                        });
                        return {...prevData, members: updatedMembers};
                    });
                    setLoading(false)
                } catch (e) {
                    toast.error(e.response.data.message)
                }

            }
        }catch (e) {
            console.log(e)
        }
    },[data])
    return (
        <>
            <Helmet>
                <title>Admin Panel</title>
            </Helmet>
            <UrsHeader />
            <div className="urs_table" style={{display: 'flex', justifyContent: 'center'}}>
                <div className="container tableParent  file" style={window.innerWidth <= 1024 ? {
                    overflow: 'auto',
                    background: 'white',
                    padding: 35
                } : {background: 'white', padding: 35}}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div>
                                <span className="custom_span" style={{cursor: 'pointer'}} onClick={() => setReport('prev')}>Download Previous Month Report</span> / <span className="custom_span"
                                style={{cursor: 'pointer'}}
                                onClick={() => setReport('now')}>Download Month Report</span></div>
                            <hr/>
                            <div>
                                <span className="custom_span" style={{cursor: 'pointer', marginRight: 5}}
                                      onClick={() => setAdd(true)}>Add</span>
                                |
                                <span className="custom_span" style={{cursor: 'pointer', margin: 5}}
                                      onClick={() => setVoip(true)}>VoIP Report</span>
                                |
                                <Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/urs_used" target="_blank"
                                      rel="noopener noreferrer">{REACT_APP_SERVER === 'Beeline' ? 'Beeline' : 'V-Office'} Used</Link>
                                |<Link className="custom_span" style={{cursor: 'pointer', margin: 5}} to="/report">Report Traffic </Link>

                            </div>
                            <div className="custom_span">Members: {data.total}</div>
                            <div className="custom_span">Pages: {Array.from({length: Math.ceil(data.total / 50)}).map((_, i) => (
                                <span className="custom_span" style={{cursor: 'pointer'}} onClick={() => handlePagination(i + 1)}
                                      key={i}>{i + 1} </span>))}</div>
                        </div>
                        <form onSubmit={handleSearch} className="d-flex flex-column align-items-start search_block"
                              style={{padding: '17px 0'}}>
                            <div>
                                <input type="text" placeholder="Գումար" value={gumar}
                                       onChange={(e) => setGumar(e.target.value)}/>
                                <input type="text" placeholder="Համար" value={search}
                                       onChange={(e) => setSearch(e.target.value)}/>
                            </div>
                            <button onClick={handleSearch} style={{padding: '2px 30px', margin: '10px 0'}}
                                    className="addBtn">Search
                            </button>
                        </form>
                    </div>
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                        <tr>
                            <th>NN</th>
                            <th>Ամսաթիվ</th>
                            <th>Օգտատեր</th>
                            <th><MatrixPop /></th>
                            <th>Հեռ</th>
                            <th>Անուն Ազգանուն</th>
                            <th>VIP</th>
                            <th>Մնացորդ (Դրամ)</th>
                            <th>Ծառայության գին</th>
                            <th><TestPop/></th>
                            <th><BillingPop billing={data.billings}/></th>
                            <th><DisabledPop /></th>
                            <th>Disabl. Date</th>
                            <th>H</th>
                            <th>R</th>
                            <th>L</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading?  <tr>
                            <td style={{textAlign: 'center'}} colSpan={17}>Loading...</td>
                        </tr>:data.members?.length ? data.members.map(d =>
                            <tr key={d?.id} style={{cursor: 'pointer'}}>
                                <td>{d?.id}</td>
                                <td>{new Date(d?.registered).toLocaleString()}</td>
                                <td onClick={() => handleId(d?.id)}>{d?.name}</td>
                                <td>{d?.matrixid}</td>
                                <td>{d?.phone}</td>
                                <td>{d?.fio}</td>
                                <td>{d?.isvip}</td>
                                <td>{d?.balance}</td>
                                <td>{d?.sgs} / {d?.sg}</td>
                                <td>
                                    <span className="d-flex align-items-end">
                                        <input className="m-0" type="checkbox"
                                               checked={d?.testmode}
                                               onChange={() => handleTest(d?.id)}/>
                                        <span style={{fontSize:'small'}}><CalendarMonthIcon
                                        onClick={() => setTest(d)} style={{fontSize: 16}}/></span></span></td>
                                <td onClick={() => {
                                    setBilling({member: d?.id, name: data?.billings[d?.admgroup]})
                                }}>
                                    {data?.billings[d?.admgroup]}
                                </td>
                                <td><span className="d-flex align-items-end justify-content-center"><input
                                    className="m-0" type="checkbox"
                                    checked={d.isblocked}
                                    onChange={() => handleBlock(d.id)}/></span>
                                </td>
                                <td>{d.sdate ? new Date(d.sdate).toLocaleString() : ''}</td>
                                <td>{d.history}</td>
                                <td>{d.lsum}</td>
                                <td>{d.dlinesSum}</td>
                                <td><span className="position-relative" onClick={() => setComment(d.id)}><ForumIcon
                                    style={{fontSize: 24}}/>
                                    {d.comment_count ? <span
                                        className="position-absolute comment_count">{d.comment_count}</span> : null}
                                </span>
                                </td>
                            </tr>) : data.status === 'ok' && !data.members.length ?
                            <tr>
                                <td style={{textAlign: 'center'}} colSpan={17}>No Data</td>
                            </tr>:<tr><td colSpan={17}/></tr>


                        }
                        {+profile.admingroup === 1 && profile.price_permision?<tr>
                            <td colSpan={15}>
                                All
                            </td>
                            <td colSpan={1}>
                                {data.totalSggumar} / {data.totalSggumarc}
                            </td>
                            <td colSpan={1}>
                                {data.gumarall || 0} / {Math.round(data.gumarall * 0.7 / 1.2) || 0}
                            </td>
                        </tr>:null}
                        </tbody>

                    </table>
                </div>
            </div>
            {test.id ? <TestMode open={test} setOpen={setTest} setData={setData}/> : null}
            {comment ? <Comment open={comment} setOpen={setComment}/> : null}
            {add ? <UrsAdd open={add} setOpen={setAdd}/> : null}
            {billing.member ? <Billing open={billing} setOpen={setBilling} setData={setData}/> : null}
            {report ? <UrsReport open={report} setOpen={setReport}/> : null}
            {voip ? <UrsVoip open={voip} setOpen={setVoip}/> : null}
        </>
    );
}

export default Urs;
