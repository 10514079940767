import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {did,global, included, input, menu, profile, sip} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {didCreate, didCreateGet, didEdit} from "../store/actions/did";
import Api from "../Api";
import TelegramCode from "./TelegramCode";
import TelegramEmail from "./TelegramEmail";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '90vh',
};

function AddAndEditDidNumbers({open, edit, setOpen}) {
    const [form, setForm] = useState({
        operator: '',
        note: '',
        caller_visible_type: 0,
        phone: '',
        profile: '',
        serverHost: '',
        username: '',
        password: '',
        line: '1'
    })

    const [telegram, setTelegram] = useState('')
    const [isEmail, setIsEmail] = useState('')
    const [session, setSession] = useState('')
    const [storage, setStorage] = useState('')
    const [loader, setLoader] = useState(false)
    const [isTel,setIsTel] = useState(false)
    const dispatch = useDispatch()
    const didCreateData = useSelector(state => state.did.didCreateData)
    const handleChange = useCallback((name, ev) => {
        if (name === 'operator' && ev === '1') {
            setForm((prevState) => ({
                ...prevState,
                operator: ev,
                phone: '374',
                profile: '',

            }))
        } else if (name === 'operator' && ev === '2') {
            setForm((prevState) => ({
                ...prevState,
                operator: ev,
                phone: '',
                profile: '',
            }))
        } else if (name === 'operator' && ev === '3') {
            setForm((prevState) => ({
                ...prevState,
                operator: ev,
                profile: '45',
                serverHost: 'sip.tg',
                phone: ''
            }))
        } else {
            setForm((prevState) => ({
                ...prevState,
                [name]: ev,

            }))
        }


    }, [])
    const handleAdd = useCallback(async () => {
        try {
            if (edit) {
                await dispatch(didEdit(form,edit.split('_')[1]))
                setOpen(false)
            } else {
                if (+form.operator === 4 && !form.username) {
                    setLoader(true)
                    const {data} = await Api.telegReg(form.phone)
                    setTelegram(form.phone)
                    setSession(data.session_id)
                    setStorage(data.storage_id)
                    setLoader(false)

                } else if(+form.operator === 4) {
                    await dispatch(didCreate({...form,phone:form.phone + '_siptg',username: ''}))
                    setOpen(false)

                }else{
                    await dispatch(didCreate({...form,phone:form.phone}))
                    setOpen(false)
                }
            }
        }catch (e) {

        }
    }, [form])

    useEffect(() => {
        (async () => {
            await dispatch(didCreateGet())
        })()
    }, [])
    useEffect(() => {
        (async () => {
            if(telegram === 'success'){
                if(+form.operator === 4) {
                    await dispatch(didCreate({...form,phone:form.phone + '_siptg',username: ''}))
                    setOpen(false)

                }
            }
            // await dispatch(didCreateGet())
        })()
    }, [telegram,form])
    useEffect(() => {
        if (edit) {
            (async () => {
                try {
                    const [did,provider] = edit.split('_')

                    const {data} = await Api.singleDid(did,provider)
                    if(+data?.did?.redirect?.provider === 45){
                        setIsTel(true)
                    }else{
                        setIsTel(false)
                    }

                    setForm({
                        operator: data?.did?.provider,
                        note: data?.did?.notes,
                        caller_visible_type: !!data?.did?.caller_visible_type,
                        phone: data?.did?.pref + data.did?.did,
                        profile: data?.did?.redirect?.provider,
                        serverHost: data?.did?.redirect?.server_host,
                        username: data?.did?.redirect?.username,
                        password: data?.did?.redirect?.password,
                        line: data?.did?.dlines
                    })
                }catch (e) {

                }
            })()
        }
    }, [edit])
    return (
        <>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="d-flex flex-row justify-content-between">
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                {menu.did[+localStorage.getItem('atsLang')  || 1]}
                            </Typography>
                            <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                        </div>

                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {did.operator[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <select
                                    disabled={edit}
                                    value={form.operator} onChange={(ev) => handleChange('operator', ev.target.value)}
                                    style={{height: 40, border: '1px solid #ccc', outline: 'none'}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    {Cookies.get('viewer') && Cookies.get('original')?didCreateData?.provider?.filter(d => d.name !== 3).map(p => (
                                        <option value={p.name} key={p.name}>{p.title}</option>
                                    )):<option value="4">telegram-gw</option>}
                                </select>
                            </label>
                            <label className="count col-md-12">
                            <span>
                               {included.phone[+localStorage.getItem('atsLang')  || 1]} :
                                {+form.profile === 45?form.username:""}
                            </span>
                                <input value={form.phone} readOnly={edit}
                                       onChange={(ev) => handleChange('phone', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="count col-md-12">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input value={form.note} onChange={(ev) => handleChange('note', ev.target.value)}/>
                            </label>
                            {edit?<label className="col-md-12 count" style={{margin: '5px 0'}}>
                                <span className="d-flex align-items-center">
                               {did.visible[+localStorage.getItem('atsLang')  || 1]}: <input style={{marginLeft:20}} className="w-auto" type="checkbox" checked={form.caller_visible_type} onChange={(ev) => handleChange('caller_visible_type', ev.target.checked ? 1 : 0)}/>
                            </span>

                            </label>:null}
                            {+form.operator === 2 || edit ? <>

                                <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {did.profile[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                    <select
                                        disabled={edit}
                                        value={form.profile} onChange={(ev) => handleChange('profile', ev.target.value)}
                                        style={{
                                            height: 40,
                                            border: '1px solid #ccc',
                                            outline: 'none'
                                        }}>
                                        <option
                                            value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {didCreateData?.fix_provider?.map(p => (
                                            <option value={p.name} key={p.name}>{p.title}</option>
                                        ))}
                                    </select>
                                </label>

                                {form.profile && +form.profile !== 45? <>
                                    {+form.profile === 27 ?
                                        <label style={{margin: '20px 0'}} className="count col-md-12">
                                        <span>
                                            Server host :
                                        </span>
                                            <input
                                                readOnly={edit}
                                                value={form.serverHost}
                                                   onChange={(ev) => handleChange('serverHost', ev.target.value)}/>
                                        </label> : null}
                                    <label style={{margin: '20px 0'}} className="count col-md-12">
                            <span>
                               {profile.username[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                        <input
                                            readOnly={edit}
                                            value={form.username}
                                               onChange={(ev) => handleChange('username', ev.target.value)}/>
                                    </label>

                                    <label style={{margin: '20px 0'}} className="count col-md-12">
                            <span>
                               {profile.pass[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                        <input
                                            readOnly={edit}
                                            type="password"
                                            value={form.password}
                                               onChange={(ev) => handleChange('password', ev.target.value)}/>
                                    </label>
                                </> : null}
                            </> : null}
                            <label style={{margin: '20px 0'}} className="count col-md-12">
                            <span>
                               {did.line[+localStorage.getItem('atsLang')  || 1]} :
                            </span>
                                <input type="number" value={form.line}
                                       onChange={(ev) => handleChange('line', ev.target.value)}/>
                            </label>

                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {loader?'Loader...':open?global.edit[+localStorage.getItem('atsLang')  || 1]:sip.add_btn[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                        </div>
                    </Box>
                </Fade>

            </Modal>
            {telegram && session ? <TelegramCode setIsEmail={setIsEmail} open={telegram} setOpen={setTelegram} session={session} setSession={setSession} storage={storage} setStorage={setStorage} setFilter={setForm}/> : null}
            {isEmail ? <TelegramEmail open={isEmail} setOpen={setIsEmail} session={session} setSession={setSession} storage={storage} setStorage={setStorage} setFilter={setForm} setTelegram={setTelegram}/> : null}
        </>

    );
}

export default AddAndEditDidNumbers;
