import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {Helmet} from "react-helmet";
import {did, menu, shortFunction, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Link, useNavigate} from "react-router-dom";
import Api from "../Api";
import KeyIcon from '@mui/icons-material/Key';
import {toast} from "react-toastify";
import {autoCallStatusRequest} from "../store/actions/autoCall";
import {profileRequest} from "../store/actions/users";
import {useDispatch, useSelector} from "react-redux";
import {REACT_APP_API_URL} from "../config";
function ClGroup(props) {
   const [datas,setData] = useState([])
   const [all,setAll] = useState(0)
   const [count,setCount] = useState(0)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const profile = useSelector(state => state.users.ursData)

    useEffect(() => {
        (async () => {
           try{
               const {data} = await Api.clGet()
               setData(data.data)
               setAll(data.all)
               setCount(data.sggumarc)
               // await dispatch(didRequest())
           }catch (e) {

           }
        })()
    }, [])
    const handlePass = useCallback(async (phone) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to drop the password?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите сбросить пароль??":
                    "Վստա՞հ եք, որ ցանկանում եք Փոփոխել գաղտնաբառը";

            if (confirm(text) === true) {
                await Api.reset_pass(phone)
                toast.success('Send message successfully')
            }

        }catch (e) {
            console.log(e)
        }
    },[])
    const handleRedirect = useCallback(async (e,id) => {
        try {
            e.preventDefault();
            const {data} = await Api.loginAsUser(id, profile.id, profile.uid)
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            await dispatch(profileRequest())
            window.open(REACT_APP_API_URL, '_blank');
            // navigate('/')

        }catch (e) {
            console.log(e)
        }
    },[profile])
    return (
        <>
            <Helmet>
                <title>
                    CLGroup
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={9}>
                                            Group Management
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell sx={{fontSize: 20,}} align="center" colSpan={3}>
                                            NN
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Ամսաթիվ
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Օգտատեր
                                        </TableCell>

                                        <TableCell
                                            align='center'
                                        >
                                            Հեռ
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Անուն Ազգանուն
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            Ծառայության գին
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {datas.map(profile => (
                                        <TableRow key={profile.id} hover role="checkbox" tabIndex={-1}>
                                            <TableCell align="left" colSpan={3}>
                                                {profile.id}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {new Date(profile.registered).toLocaleString()}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                <Link to="/" onClick={(e) => handleRedirect(e,profile.id)}>
                                                    {profile.name}

                                                </Link>

                                            </TableCell>

                                            <TableCell
                                                align='center'
                                            >
                                                {profile.phone}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {profile.fio}
                                            </TableCell>
                                            <TableCell
                                                align='center'
                                            >
                                                {profile.sgumar}

                                            </TableCell>
                                            <TableCell
                                                className="auto_status"
                                                onClick={() => handlePass(profile.phone)}
                                                align='center'
                                            >
                                                <KeyIcon className="auto_status" sx={{color: '#CD405F', fontSize: 25}}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                    {/*    );*/}
                                    {/*})}*/}
                                    <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="right" colSpan={12} sx={{fontSize: 13}}>
                                             {all} / {count}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>

                </div>
                {/*{create ? <AddAndEditClGroup open={create} edit={false} setOpen={setCreate}/> : null}*/}
                {/*{edit ? <AddAndEditClGroup open={edit} edit={edit} setOpen={setEdit}/> : null}*/}
            </Wrapper>
        </>

    );
}

export default ClGroup;
