import React from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";

function TelegramPage(props) {
    return (
        <Wrapper>
            <div className="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <div style={{padding:20}}>
                        <h3>Telegram</h3>

                    </div>
                </Paper>
            </div>
            <div className="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <div style={{padding:20}}>
                        <h3>Telegram BOT</h3>
                    </div>
                </Paper>
            </div>
        </Wrapper>
    );
}

export default TelegramPage;
