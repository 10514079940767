import React, {useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {global, menu, sip} from "../translate";
import TableBody from "@mui/material/TableBody";
import Api from "../Api";
import {Link} from "react-router-dom";


function SipIp({setViewIp}) {
    const [ipList,setIpList] = useState([])
    useEffect(() => {
        (async () => {
            const {data} = await Api.sip_ip()
            setIpList(data.data)
        })()
    }, [])
    return (
        <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
            <TableContainer
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow className="sip_header">
                            <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                {menu.sip[+localStorage.getItem('atsLang') || 1]}
                            </TableCell>

                        </TableRow>
                        <TableRow className="sip_num">

                            <TableCell align='center'>
                                IP

                            </TableCell>
                            <TableCell align='center' >
                                {global.count[+localStorage.getItem('atsLang') || 1]}

                            </TableCell>
                            <TableCell
                                align='center'
                            >
                                {sip.action[+localStorage.getItem('atsLang') || 1]}

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody id="dataBody">
                        {ipList.length  ? ipList.map((s, _) => (
                            <TableRow key={_} hover role="checkbox" tabIndex={-1}>

                                <TableCell className="td_notes" align="center">
                                    {s.ipaddr}
                                </TableCell>
                                <TableCell align="center">
                                    {s.total_entries}
                                </TableCell>

                                <TableCell onClick={() => setViewIp(false)} align="center">
                                    <Link to={`/sip_number?search=${s.ipaddr}`}>View</Link>
                                </TableCell>

                            </TableRow>

                        )) : <TableRow hover role="checkbox" tabIndex={-1}>


                            <TableCell align="center" colSpan={3}>
                                no data

                            </TableCell>


                        </TableRow>
                        }
                        {/*    );*/}
                        {/*})}*/}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

    );
}

export default SipIp;
