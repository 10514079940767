import React from 'react';
import {Handle, Position} from "@xyflow/react";

function CustomNode(prop) {
    const {id,data} = prop;
    return (
        <>
            <Handle position={Position.Top} type='target' id={data.type + '_target_'  + id} />

           <div className="custom_node" style={data.color?{borderColor:data.color}:{}}>
               <div><b>{data.type}</b></div>
               <div>{data.label}</div>
           </div>
            <Handle type="source" position={Position.Bottom} id={data.type+ '_source_' + id}/>




        </>
    );
}

export default CustomNode;
