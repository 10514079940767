import React, {useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";
import {menu} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import {Bar} from "react-chartjs-2";
import Api from "../Api";

function Statistic(props) {
    const [stat,setStatic] = useState({})
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    useEffect(() => {
        (async () => {
           try{
               const {data} = await Api.statistic(query.type || 'week');
               let obj = {}

               data.finalData.map(l => {
                   obj[l.date]=l.count
               })
               setStatic(obj)
           }catch (e) {
               console.log(e)
           }
        })()
    },[location.search,])
    return (
        <Wrapper>
            <div className="tableParent" id="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>

                    <div className="w-100"
                         style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex',padding:'0 30px', flexDirection: 'row', alignItems: 'center'}}>
                            <h2 style={{fontSize: 20, margin: 0}}>
                                <Link style={{fontSize: 20, margin: 0}} to="/logs">{menu.logs[+localStorage.getItem('atsLang') || 1]}</Link>
                            </h2>
                            <div className="header_border" style={{height: 40, marginBottom: 15}}/>

                            <h2 style={{fontSize: 20, margin: 0}}>
                                Activities
                            </h2>
                        </div>
                    </div>
                    <hr style={{borderColor: '#d4d4d4', margin: 0}}/>
                    <div className="btn_block justify-content-start">
                        <button onClick={() => navigate('/statistic?type=week')}>Week</button>
                        <button onClick={() => navigate('/statistic?type=month')}>Month</button>
                        <button onClick={() => navigate('/statistic?type=year')}>Year</button>
                    </div>
                    <div>
                        <Bar
                            type="bar"
                            data={{
                                labels: Object.keys(stat),
                                // labels: [],
                                datasets: [{
                                    // data: [],
                                    data: Object.values(stat),
                                    backgroundColor: [
                                        'rgb(255, 99, 132)',
                                        'rgb(54, 162, 235)',
                                        'rgb(255, 205, 86)',
                                    ]
                                },
                                ]
                            }}

                            options={{
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                },
                                scales: {
                                    y: {
                                        beginAtZero: true
                                    }
                                }
                            }}
                        />
                    </div>

                </Paper>
            </div>


        </Wrapper>
    );
}

export default Statistic;
