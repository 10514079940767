import React, {useCallback, useEffect} from 'react';
import Wrapper from "../components/Wrapper";
import {addEdge, Background, Controls, MiniMap, ReactFlow, useEdgesState, useNodesState,} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import Paper from "@mui/material/Paper";
import Api from "../Api";
import CustomNode from "../components/CustomNode";
import Utils from "../Utils";


const initialNodes = [];
const nodeTypes = {
    custom: CustomNode,
};
let sourceColorMap = {
    '55': 'blue',
    '2': 'green',
    '3': 'red',
};

const getColorForSource = (source) => sourceColorMap[source] || 'gray';
const initialEdges = [];

function DragConnect(props) {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => {
            console.log(params)
            let {sourceHandle, targetHandle} = params;
            if (sourceHandle.split('_')[0] === targetHandle.split('_')[0]) {
                return
            }
            setEdges((eds) => addEdge(params, eds))
        },
        [setEdges],
    );
    const coloredEdges = edges.map((edge) => ({
        ...edge,
        style: {stroke: getColorForSource(edge.source), strokeWidth: 1, animated: true},
    }));
    useEffect(() => {
        (async () => {
            const {data} = await Api.drag()
            let color = 'white'
            //-----DID-----//
            let currentX = 50;
            let labelLength = 0
            let nodeWidth = 0
            let position = 0
            let fetchedEdgeDid = [];
            let m = null
            let fetchedNodesDid = data.data.did.filter((s) => s.child).map((s, _) => {
                labelLength = `${s.pref + s.did} ${s.notes}`.length;
                nodeWidth = labelLength * 8;
                position = {x: currentX, y: _ % 2 === 0 ? 0 : 100};
                currentX += nodeWidth;
                color = Utils.getRandomColor();
                sourceColorMap = {...sourceColorMap, [s.pref.toString() + s.did.toString()]: color};
                return ({
                    id: s.pref.toString() + s.did.toString(),
                    type: "custom",
                    position,
                    data: {color, label: `${s.pref + s.did} ${s.notes}`, type: 'DID'}
                })
            })

            data.data.did.filter((s) => s.child).map(s => {
                if (s.child?.output?.toString().length <= 4) {
                    m = data.data.short.find(sh => sh.virtual.toString() === s.child?.output.toString())
                    fetchedEdgeDid.push(
                        {
                            id: s.pref.toString() + s.did.toString(),
                            source: s.pref.toString() + s.did.toString(),
                            sourceHandle: 'DID_source_' + s.pref.toString() + s.did.toString() || 'source',
                            target: s.child.output,
                            // type: 'step',
                            targetHandle: `${m.isivr ? 'IVR' : 'Short'}_target_` + s.child.output || 'target',
                        }
                    )
                }
            })
            m = null;
            //-----SHORT AND SIP-----//
            const uniqueNodes = Array.from(
                new Map(data.data.short.map((node) => [node.id, node])).values()
            );
            let uniqueSip = []
            let uniqueFile = []
            currentX = 50;

            let fetchedNodesShort = uniqueNodes.filter((s) => s.child.length).map((s, _) => {
                    m = data.data.did.filter((s) => s.child).find(d => d.child.output.toString() === s.virtual.toString())
                    position = fetchedNodesDid.find(d => d.id === m.child.input)
                    m = uniqueNodes.filter((s) => s.child.length).find(d => d.virtual.toString() === m.child.output)
                    labelLength = `${s.virtual} (${s.name})`.length;
                    nodeWidth = labelLength * 8;
                    position = {...position.position, y: _ % 2 === 0 ? 400 : 500};
                    currentX += nodeWidth;
                    color = Utils.getRandomColor();
                    sourceColorMap = {...sourceColorMap, [s.virtual.toString()]: color};

                    return {
                        id: s.virtual.toString(),
                        type: "custom",
                        position,
                        data: {color, label: `${s.virtual} (${s.name})`, type: m.isivr ? 'IVR' : 'Short'}
                    };
                });
            let fetchedNodesFile = data.data.files;
            let fetchedNodesSip = data.data.sip;
            let fetchedEdgeSip = [];
            let fetchedEdgeFile = [];
            fetchedNodesFile.map((s, _) => {
                uniqueNodes.map(u => {
                    u.child.map(c => {
                        if (c.buddy === s.fname) {
                            uniqueFile.push(s)
                        }
                    })
                })
            })
            fetchedNodesSip.map((s, _) => {
                uniqueNodes.map(u => {
                    u.child.map(c => {
                        if (c.buddy === s.buddy) {
                            uniqueSip.push(s)
                        }
                    })
                })
            })

            uniqueSip = Array.from(
                new Map(uniqueSip.map((node) => [node.buddy, node])).values()
            );
            uniqueFile = Array.from(
                new Map(uniqueFile.map((node) => [node.fname, node])).values()
            );
            data.data.short.filter((s) => s.child.length).forEach((shortNode, shortIndex) => {
                if(shortNode.isivr){
                    shortNode.child.forEach((child, _) => {
                        fetchedEdgeFile.push(
                            {
                                id: child.buddy.toString(),
                                source: shortNode.virtual.toString(),
                                sourceHandle: 'IVR_source_' + shortNode.virtual.toString() || 'source',
                                target: child.buddy,
                                targetHandle: 'File_target_' + child.buddy || 'target',
                                // type: 'step',
                            })
                    });
                }else{
                    shortNode.child.forEach((child, _) => {
                        fetchedEdgeSip.push(
                            {
                                id: child.buddy.toString(),
                                source: shortNode.virtual.toString(),
                                sourceHandle: 'Short_source_' + shortNode.virtual.toString() || 'source',
                                target: child.buddy,
                                targetHandle: 'SIP_target_' + child.buddy || 'target',
                                // type: 'step',
                            })
                    });
                }
            });
            fetchedEdgeSip = Array.from(
                new Map(fetchedEdgeSip.map((node) => [node.id, node])).values()
            )
            fetchedEdgeFile = Array.from(
                new Map(fetchedEdgeFile.map((node) => [node.id, node])).values()
            )
            currentX = 100
            fetchedNodesSip = uniqueSip.map((s, _) => {
                uniqueNodes.filter((s) => !s.isivr).map(u => {
                    if (u.child.find(c => c.buddy.toString() === s.buddy.toString())) {
                        m = u
                    }
                })
                position = fetchedNodesShort.find(d => d.id === m.virtual.toString())
                labelLength = `${s.buddy} ${s.issip ? '(S)' : ''}  ${s.notes ? '- ' + s.notes : ''}`.length;
                nodeWidth = labelLength * 8;
                position = {...position.position, y: _ % 2 === 0 ? 600 : 700};
                currentX += nodeWidth;
                color = Utils.getRandomColor();
                sourceColorMap = {...sourceColorMap, [s.buddy.toString()]: color};
                return ({
                    id: s.buddy,
                    type: "custom",
                    position,
                    data: {color:position.data?.color,label: `${s.buddy} ${s.issip ? '(S)' : ''}  ${s.notes ? '- ' + s.notes : ''}`, type: 'SIP'}
                })
            })
            fetchedNodesFile = uniqueFile.map((s, _) => {
                uniqueNodes.filter((s) => s.isivr).map(u => {
                    if (u.child.find(c => c.buddy.toString() === s.fname.toString())) {
                        m = u
                    }
                })
                position = fetchedNodesShort.find(d => d.id === m.virtual.toString())
                labelLength = `${s.fname}`.length;
                nodeWidth = labelLength * 8 * _;
                position = {x:position.position.x + _*10, y: _ % 2 === 0 ? 800 + _*10 : 900+ _*10};
                currentX += nodeWidth;
                color = Utils.getRandomColor();
                sourceColorMap = {...sourceColorMap, [s.fname.toString()]: color};
                return ({
                    id: s.fname.toString(),
                    type: "custom",
                    position,
                    data: {color:position.data?.color,label: `${s.fname}`, type: 'File'}
                })
            })

            setNodes([...fetchedNodesDid,
                ...fetchedNodesFile,
                ...fetchedNodesSip,
                ...fetchedNodesShort,
            ])
            setEdges([...fetchedEdgeDid, ...fetchedEdgeSip,...fetchedEdgeFile])
        })()
    }, [])
    return (
        <Wrapper>
            <div className="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <div style={{padding:20}}>
                        <h5>Controllers</h5>
                    </div>
                    <div style={{width: '100%', height: '100vh'}}>
                        <ReactFlow
                            nodes={nodes}
                            nodeTypes={nodeTypes}
                            edges={coloredEdges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                        >
                            <Controls/>
                            <MiniMap/>
                            <Background variant="dots" gap={12} size={1}/>
                        </ReactFlow>
                    </div>
                </Paper>
            </div>
        </Wrapper>
    );
}

export default DragConnect;
