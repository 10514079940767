import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Api from "../Api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {global, menu, sip} from "../translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {TableFooter} from "@mui/material";

const {REACT_APP_SERVER} = process.env;

let color = REACT_APP_SERVER === 'ATS' ? '#0973b9' : '#7ebc0a'
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
let newArr = []

function Altegio(props) {
    const [altegio, setAltegio] = useState([])
    const [single, setSingle] = useState({label: 'Select', value: ''})
    const userData = useSelector((state) => state.users.crmData)
    const navigate = useNavigate()
    const [sipNum, setSip] = useState([]);
    const handleDelete = useCallback(async (id) => {
        try {
            await Api.altegio_crmDelete(id)
            setAltegio(prevState => prevState.filter(s => s.id !== id))
        }catch (e) {
            toast.error(e.response.data.message);
        }
    }, [])

    const handleSipSelect = useCallback((ev) => {
        setSingle(ev);
    }, [])
    useEffect(() => {
        (async () => {
            if (sipNum.length) {
                const {data} = await Api.altegio_get();
                setAltegio(data.altegio.map(s => ({
                    sip: s.sip,
                    id: s.id,
                    notes: sipNum.find(f => +f.number === +s.sip)?.context
                })));
            }
        })()
    }, [sipNum])
    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSip(data.inputNumber?.sip)
            } catch (e) {
            }
        })()
    }, []);

    const handleSave = useCallback(async (item) => {
        try {
            const {data} = await Api.altegio_create(single.value)

            setAltegio(data.altegio.map(s => ({
                sip: s.sip,
                id: s.id,
                notes: sipNum.find(f => +f.number === +s.sip)?.context
            })));
            toast.success(global.create[+localStorage.getItem('atsLang')])

        } catch (e) {
            toast.error(e.response.data.message);
        }
    }, [single, sipNum])
    useEffect(() => {
        (async () => {
            const {data} = await Api.altegio_check();
            if (!data.altegio) {
                navigate('/crm_integration')
            }
        })()
    }, [])
    return (
        <Wrapper>
            <div className="tableParent" id="tableParent">
                <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                    <TableContainer
                    >
                        <Table stickyHeader
                               aria-label="sticky table">
                            <TableHead>
                                <TableRow className="sip_header">
                                    <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                        {menu.sip[+localStorage.getItem('atsLang') || 1]} - {userData?.altegio?.code}
                                    </TableCell>

                                </TableRow>

                                <TableRow className="sip_num">


                                    <TableCell style={{cursor: 'pointer', width: '33%'}} align='center'>
                                        {sip.phone[+localStorage.getItem('atsLang') || 1]}

                                    </TableCell>
                                    <TableCell style={{cursor: 'pointer', width: '33%'}} align='center'>
                                        {sip.note[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>
                                    <TableCell
                                        style={{cursor: 'pointer', width: '33%'}}
                                        className="action"
                                        align='center'
                                    >
                                        {sip.action[+localStorage.getItem('atsLang') || 1]}
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody id="dataBody">
                                {altegio.length ? altegio.map((s, _) => (
                                    <TableRow key={_} hover role="checkbox" tabIndex={-1}>

                                        <TableCell style={{cursor: 'pointer', width: '33%'}} align="center">
                                            {s.sip}
                                        </TableCell>
                                        <TableCell style={{cursor: 'pointer', width: '33%'}} className="td_notes"
                                                   align="center">
                                            {s.notes}
                                        </TableCell>
                                        <TableCell style={{cursor: 'pointer', width: '33%'}} className="action"
                                                   align="center">
                                            <p style={{
                                                fontSize: 13,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center'
                                            }}>

                                                    <span onClick={() => handleDelete(s.id)}
                                                          className="delete"><FontAwesomeIcon
                                                        icon={faTrashCan}/></span>

                                            </p>
                                        </TableCell>

                                    </TableRow>

                                )) : null}

                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    <TableCell
                                        align="center" style={{cursor: 'pointer', width: '33%'}}>
                                        <div>
                                            <Select
                                                styles={customStyles}
                                                menuPlacement="auto"
                                                menuPosition="fixed"
                                                onChange={(ev) => handleSipSelect(ev)}
                                                menuPortalTarget={document.body}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                value={single}
                                                name="color"
                                                options={[{number: '', label: 'Select value'}, ...sipNum.map(k => (
                                                    {value: +k.number, label: `${k.number} ${k.context || ''}`}
                                                ))]}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{cursor: 'pointer', width: '33%'}} className="td_notes"
                                               align="center">

                                    </TableCell>
                                    <TableCell style={{cursor: 'pointer', width: '33%'}} className="action"
                                               align="center">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button className="addBtn" onClick={handleSave}>
                                                Save
                                            </button>
                                        </div>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                            <TableFooter style={{height: 40}}>
                                <TableRow>
                                    <TableCell colSpan={3} className="action" align="center"/>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                </Paper>

            </div>
            {/*{single?<AltegioCall open={single} setOpen={setSingle}/>:null}*/}
        </Wrapper>
    );
}

export default Altegio;
