import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {did, included, input, menu, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AddAndEditDidNumbers from "../components/AddAndEditDidNumbers";
import {didDelete, didDeleteLocal, didRequest} from "../store/actions/did";
import _ from 'lodash'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import qs from "query-string";
import SearchIcon from "@mui/icons-material/Search";
import Api from "../Api";
import SingleDidNumbers from "../components/SingleDidNumbers";
import Utils from "../Utils";
import TablePagination from "@mui/material/TablePagination";
import Cookies from "js-cookie";

const titleArr = [
    sip.phone[+localStorage.getItem('atsLang')  || 1],
    sip.note[+localStorage.getItem('atsLang')  || 1],
    did.active[+localStorage.getItem('atsLang')  || 1],
    did.date[+localStorage.getItem('atsLang')  || 1],
    did.no_line[+localStorage.getItem('atsLang')  || 1],
];
let descArr = []
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function DidNumbers(props) {
    const didData = useSelector(state => state.did.didData)
    const didStatus = useSelector(state => state.did.didStatus)
    const page = useSelector(state => state.did.page)
    const count = useSelector(state => state.did.count)
    const countLine = useSelector(state => state.did.countLine)
    const [edit, setEdit] = useState(0)
    const [create, setCreate] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [copied, setCopied] = useState(false)
    const [single, setSingle] = useState(0)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleDelete = useCallback(async (id, profile, username,provider) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                if (+profile === 45 && username) {
                    await Api.telegRegDelete(username)
                }
                await dispatch(didDeleteLocal(id,provider))
                await dispatch(didDelete(id,provider))
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    useEffect(() => {
        (async () => {
            await dispatch(didRequest(query.search,Cookies.get("did") ||query.limit || 10, query.page || 1,))
        })()
    }, [location.search])
    const handleSearch = useCallback((ev) => {
        query.search = ev.target.value
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [])
    const profile = useSelector(state => state.users.ursData)
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("did",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    // useEffect(() => {
    //     if (didData) {
    //         setCount(Utils.getCount(didData, 'history'))
    //         setCount1(Utils.getCount(didData, 'dlines'))
    //     }
    // }, [didData])
    return (
        <>
            <Helmet>
                <title>
                    {menu.did[+localStorage.getItem('atsLang')  || 1]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={5}>
                                            {did.did[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15, padding: '0 35px',}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end align-items-center">
                                                <label className="search">
                                                    <input
                                                        value={query.search}
                                                        onChange={handleSearch}
                                                        placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                                </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        />
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.forward[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.note[+localStorage.getItem('atsLang')  || 1]}

                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.active[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.date[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.line[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {did.no_line[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!didData.length && didStatus === 'request' ?

                                        <TableRow hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                                Loading...
                                            </TableCell>
                                        </TableRow> :
                                        !didData.length && didStatus === 'success' ?
                                            <TableRow hover role="checkbox" tabIndex={-1}>

                                                <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                                    no data

                                                </TableCell>
                                            </TableRow> : didData.map(s => (
                                                <TableRow key={s.pref + s.did+s.provider} hover role="checkbox" tabIndex={-1}>
                                                    <TableCell align="center">
                                                        {s.redirect?.st === 10 || (!s.redirect?.st && s.ord > 0) ?
                                                            <CheckCircleIcon style={{color:color,fontSize:18,marginLeft:10}}/> : null}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.pref + s.did}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s?.output?.output}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.notes}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {s.in} - {s.out}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.inTimeT?Utils.secToHours(s.inTimeT):''} - {s.outTimeT?Utils.secToHours(s.outTimeT):''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {s.dlines}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{color: 'red'}}>
                                                        {+s.nodline || ''}

                                                    </TableCell>

                                                    <TableCell align="center" className="action">
                                                        <p style={{display: "flex", justifyContent: 'center'}}>
                                                    <span onClick={() => setSingle(s.pref + s.did + '_' + s.provider)}
                                                          className="edit"><FontAwesomeIcon
                                                        icon={faEye}/></span>
                                                            <span onClick={() => setEdit(s.pref + s.did + '_' + s.provider)}
                                                                  className="edit"><FontAwesomeIcon
                                                                icon={faPenToSquare}/></span>
                                                            {!_.isEmpty(profile) || s.provider === 4?
                                                                <span
                                                                    onClick={() => handleDelete(s.pref + s.did, s.redirect?.provider, s.redirect?.username,s.provider)}
                                                                    className="delete"><FontAwesomeIcon
                                                                    icon={faTrashCan}/></span> : null}
                                                        </p>
                                                    </TableCell>

                                                </TableRow>

                                            ))
                                    }
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            Count
                                        </TableCell>
                                        <TableCell align="center" colSpan={6}>
                                            {countLine || 0}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, didData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel(titleArr, didData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.copy(setCopied)}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,]}
                                component="div"
                                count={+count}
                                rowsPerPage={Cookies.get("did") ||query.limit || 10}
                                page={+page - 1}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev, newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>
                        {copied ? <div className="copied">Copied</div> : null}
                    </Paper>

                </div>
                {create ? <AddAndEditDidNumbers open={create} edit={false} setOpen={setCreate}/> : null}
                {single ? <SingleDidNumbers open={single} edit={single} setOpen={setSingle}/> : null}
                {edit ? <AddAndEditDidNumbers open={edit} edit={edit} setOpen={setEdit}/> : null}
            </Wrapper>
        </>

    );
}

export default DidNumbers;
