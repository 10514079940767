import React, {useCallback, useEffect, useRef, useState} from 'react';
import {historyHeader, input, lang} from "../translate";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-hy';
import 'jquery-ui/ui/i18n/datepicker-ru';
import 'jquery-ui/ui/i18n/datepicker-en-AU';
import Api from "../Api";
import {historyRequest} from "../store/actions/history";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Cookies from "js-cookie";

function HistoryFilter(props) {
    const dateInputStartRef = useRef(null);
    const dateInputEndRef = useRef(null);
    const historyData = useSelector(state => state.history.historyData)
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        if (name === 'startTime' || name === 'endTime') {
            setFilters((prev) => ({
                ...prev,
                [name]: ev
            }));
            query[name] = ev
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
            query[name] = ev.target.value
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [location.search])
    useEffect(() => {
        $.datepicker.setDefaults($.datepicker.regional[lang[+localStorage.getItem('atsLang')]]);

        $(dateInputEndRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });
        $(dateInputStartRef.current).datepicker({
            dateFormat: 'yy-mm-dd', // Customize the date format
            changeMonth: true,
            changeYear: true,
            defaultDate: '+1w',
        });

        $(dateInputStartRef.current).on('change', function () {
            handleChange('startTime', $(this).val());
        });
        $(dateInputEndRef.current).on('change', function () {
            handleChange('endTime', $(this).val());
        });

    }, [location.search]);
    const [select, setSelect] = useState(true)
    const [array, setArray] = useState(null)
    const dispatch = useDispatch()

    const [filters, setFilters] = useState({
        startTime: new Date(),
        endTime: new Date(),
        number: '',
        status: '',
        disposition: '',
        line: '',
    })


    useEffect(() => {
        let yourDate = new Date()
        const offset = yourDate.getTimezoneOffset()
        yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000))
        if (!_.isEmpty(query)) {
            setFilters({
                endTime: yourDate.toISOString().split('T')[0],
                startTime: yourDate.toISOString().split('T')[0],
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                line: query.line || '',

            })
            if (query.endTime) {
                setFilters((prev) => ({
                    ...prev,
                    endTime: query.endTime
                }))
            }
            if (query.startTime) {
                setFilters((prev) => ({
                    ...prev,
                    startTime: query.startTime
                }))
            }
        } else {
            query.startTime = yourDate.toISOString().split('T')[0]
            query.endTime = yourDate.toISOString().split('T')[0]
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [])


    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(historyRequest(Cookies.get("history") ||query.limit || 10, 1, filters));
    }, [filters])

    useEffect(() => {
        (async () => {
            try {
                const {data} = await Api.getAllowed()
                setSelect(data.inputNumber)
            } catch (e) {
            }
        })()
    }, []);
    useEffect(() => {
        let resultObjectDis = {}
        let resultObjectLine = {}
        let resultObjectStatus = {}
        let resultObjectIvr = {}
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.status.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.status[i];
            resultObjectStatus[name] = historyData?.facet_counts?.facet_fields?.status[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.ivr.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.ivr[i];
            resultObjectIvr[name] = historyData?.facet_counts?.facet_fields?.ivr[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.line.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.line[i];
            resultObjectLine[name] = historyData?.facet_counts?.facet_fields?.line[i + 1];
        }
        for (let i = 0; i < historyData?.facet_counts?.facet_fields?.disposition.length; i += 2) {
            const name = historyData?.facet_counts?.facet_fields?.disposition[i];
            resultObjectDis[name] = historyData?.facet_counts?.facet_fields?.disposition[i + 1];
        }
        setArray({disposition:resultObjectDis,line:resultObjectLine,status:resultObjectStatus,ivr:resultObjectIvr})
    }, [historyData]);
    return (
        <div className="row" style={{margin: 2, alignItems: 'end'}}>
            <div className="col-md-11">
                <div className="row history_page">
                    <label className="col-md-12">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.phone[+localStorage.getItem('atsLang')  || 1]}</span>
                            <input value={filters.number} onChange={(ev) => handleChange('number', ev)}/>

                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.start[+localStorage.getItem('atsLang')  || 1]}</span>
                            <input
                                ref={dateInputStartRef}
                                type="text"
                                value={query.startTime}
                            />
                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.end[+localStorage.getItem('atsLang')  || 1]}</span>
                            <input
                                ref={dateInputEndRef}
                                type="text"
                                value={query.endTime}
                            />
                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.calls[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.status} onChange={(e) => handleChange('status', e)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="Incoming Call" id="1">Incoming Call {filters.status && filters.status !== 'Incoming Call' ? '' : array?.status['Incoming Call'] ? array.status['Incoming Call'] : 0}</option>
                                <option value="Out Call" id="2">Out Call {filters.status && filters.status !== 'Out Call' ? '' : array?.status['Out Call'] ? array.status['Out Call'] : 0}</option>
                                <option value="Local Call" id="3">Local Call {filters.status && filters.status !== 'Local Call' ? '' : array?.status['Local Call'] ? array.status['Local Call'] : 0}</option>
                                <option value="Redirect Call" id="4">Redirect Call {filters.status && filters.status !== 'Redirect Call' ? '' : array?.status['Redirect Call'] ? array.status['Redirect Call'] : 0}</option>
                            </select>
                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.virtual[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.number} onChange={ev => handleChange('number', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {select?.provider?.map(s => (
                                    <option key={s.number} value={s.number}>{s.number} {s.name || ''}</option>
                                ))}
                                {select?.fix_provider?.map(s => (
                                    <option key={s.number} value={s.number}>{s.number} {s.name || ''}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.status[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.disposition} onChange={ev => handleChange('disposition', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option
                                    value='ANSWERED'>ANSWERED {filters.disposition && filters.disposition !== 'ANSWERED' ? '' : array?.disposition?.ANSWERED}</option>
                                <option value="NO ANSWER">NO
                                    ANSWER {filters.disposition && filters.disposition !== 'NO ANSWER' ? '' : array ? array.disposition['NO ANSWER'] : 0}</option>
                                <option
                                    value="BUSY">BUSY {filters.disposition && filters.disposition !== 'BUSY' ? '' : array?.disposition?.BUSY || 0}</option>
                                <option
                                    value="FAILED">FAILED {filters.disposition && filters.disposition !== 'FAILED' ? '' : array?.disposition?.FAILED || 0}</option>
                            </select>
                        </div>
                    </label>
                    <label className="col-md-4">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.call_stop[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.line} onChange={ev => handleChange('line', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                <option value="IVR">IVR {filters.line && filters.line !== 'IVR' ? '' : array?.ivr?.IVR || 0}</option>
                                <option value="MusicHold">MusicHold {filters.line && filters.line !== 'MusicHold' ? '' : array?.line?.MusicHold || 0}</option>
                            </select>
                        </div>
                    </label>
                    <label className="col-md-6">
                        <div className="d-flex align-items-center" style={{margin: '10px 0'}}>
                            <span>{historyHeader.sip[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.number} onChange={(ev) => handleChange('number', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {select?.sip?.map(s => (
                                    <option key={s.number} value={s.number}>{s.number} {s.context || ''}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="col-md-6">
                        <div className="d-flex align-items-start" style={{margin: '10px 0'}}>
                            <span>{historyHeader.included[+localStorage.getItem('atsLang')  || 1]}</span>
                            <select value={filters.number} onChange={(ev) => handleChange('number', ev)}>
                                <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                {select?.allowed?.map(s => (
                                    <option key={s.number} value={s.number}>{s.number} {s.context || ''}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                </div>
            </div>
            <div className="col-md-1">
                <button className="addBtnBig" onClick={handleSubmit} style={{
                    fontSize: 11,
                    padding: '10px 17px'
                }}>
                    {input.search[+localStorage.getItem('atsLang')  || 1]}
                </button>
            </div>
        </div>
    );
}

export default HistoryFilter;
